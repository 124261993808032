/* ProgressCards.css */
.progress-cards-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
 
  .progress-card {
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .progress-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .Prgress{
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 550;
    color: black;
  }

  .progress-card-content {
    display: flex;
    align-items: center;
  }
  
  .progress-card-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    border: 1px solid rgba(226, 234, 253, 1);
    /* padding: 12px; */
    background-color: rgba(226, 234, 253, 1);
    border-radius: 50%;
  }
  
  .progress-card-info {
    display: flex;
    flex-direction: column;
  }
  
  .progress-card-title {
    font-size: 0.875rem;
    color: #4a5568;
  }
  
  .progress-card-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2d3748;
  }
  
  /* Responsive adjustments */
  @media (min-width: 640px) {
    .progress-cards-container {
      flex-direction: row;
      flex-wrap: wrap;
    }
  
    .progress-card {
      flex: 1 1 calc(50% - 0.5rem);
    }
  }
  
  @media (min-width: 1024px) {
    .progress-card {
      flex: 1 1 calc(20% - 0.8rem);
    }
  }



/* vfrvrfv */


.Progress {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.progress-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three cards per row */
  gap: 1em 4em; /* Horizontal gap is 20px, vertical gap is 2em */
}

.progress-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.progress-card-content {
  display: flex;
  align-items: center;
}

.progress-card-icon img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.progress-card-info {
  display: flex;
  flex-direction: column;
}

.progress-card-title {
  font-size: 16px;
  font-weight: bold;
}

.progress-card-value {
  font-size: 24px;
  color: #333;
}
