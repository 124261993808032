.container {
    font-family: Arial, sans-serif;
    padding: 50px;
  }
  
  .controls {
    display: flex;
    justify-content:space-between;
    margin-bottom: 20px;
  }
  
  .entries-control select {
    margin: 0 5px;
  }
  
  .search-control input {
    padding: 5px;
    width: 45em;
    border: 1px solid rgba(158, 158, 158, 1);
    border-radius: 8px;
    align-items: center;
  }

  .invoice-header {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust spacing between elements */
  }
  
  .invoice-header h2 {
    margin: 0;
    flex-shrink: 0; /* Prevents the title from shrinking */
  }
  
  .export-button,
  .search-bar {
    margin: 0;
  }
  
  
  /* .invoice-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-table th, .invoice-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-size: 14px;
  }
  
  .invoice-table th {
    background-color: #f2f2f2;
  }
   */
  /* .invoice-table tr:nth-child(even) {
    background-color: #fff5e6;
  } */
  
  .details-btn {
    background-color:rgba(0, 80, 164, 1);
    color: white;
    border: 1px solid rgba(0, 80, 164, 1) ;
    border-radius: 8px;
    text-decoration: none;
    padding: 5px 10px;
    font-size: 12px !important;
    cursor: pointer;
  }
  
  .pagination {
    margin-top: 20px;
    text-align: center;
    display: block;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #0056b3;
    color: white;
  }
  
  .back-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    text-decoration: none;
  }

  .table {
    
    border: 1px solid rgba(0, 80, 164, 1);
    padding: 2px;
    border: radius 8px;
    margin-bottom: 2px;
  }
  

  .inspections-table {
    width: 100%;
    min-width: 1000px; /* Adjust this value based on your content */
    /* table-layout: fixed; */
  }
  
  
  .inspections-table th, .inspections-table td {
    font-size: 14px;
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    text-overflow: ellipsis;
  
  
  }
  
  .inspections-table th {
    cursor: pointer;
    
  }
 .inspections-table{
    /* border: 1px solid rgba(0, 80, 164, 1); */
    padding: 2px;     
    border-radius: 2em !important;
 }
  
.invoiceTableBoxing{
  
  padding: 10px;
  border: 1px solid rgba(0, 80, 164, 1);
  border-radius: 22px;
}