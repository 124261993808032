.company-icon-details {
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .icon-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .company-details-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .large-icon {
    width: 100px;
    height: 100px;
    margin-left: 20px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }