.weekly-status-chart {
  width: 100%;
  margin: 0 auto;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.chart-tabs, .chart-series-tabs {
  display: flex;
  gap: 20px;
}

.chart-tabs span, .chart-series-tabs span {
  cursor: pointer;
  color:   rgba(71, 97, 149, 0.5);
  font-size: 14px;
  font-weight: 500;
}

.active-tab {
  color:#002A7E !important;
  font-weight: bold;
  /* border-bottom: 2px solid #0050A4; */
}

.chart-series-tabs {
  color: #0050A4;
  font-weight: 500;
}

.chart-series-tabs span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.chart-series-tabs span:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.chart-series-tabs span:nth-child(1):before {
  background-color: #0050A4;
}

.chart-series-tabs span:nth-child(2):before {
  background-color: rgba(11, 116, 78, 0.4);
}
