.revenue-overview {
    background-color: #EDF4FC;
    padding: 40px;
    padding-top: 20px;
    border-radius: 10px;
    /* width: 40%; */
  }
 
  .revenue-box {
    background-color: #0056b3;
    color: white;
    padding: 20px;
    margin-top: 2.2em;
    /* margin-bottom: 4em; */
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .revenue-content {
    display: flex;
    flex-direction: column;
  }
  
  .revenue-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .revenue-amount {
    font-size: 18px;
    font-weight: bold;
    color: white ;
    margin-top: 15px;
  }
  
  .revenue-month {
    display: flex;
    margin-left: 1em;
    background-color: white;
    color: #0050A4;
    border: 1px solid black;
    align-items: center;
    margin-left: 10px; /* Adjust spacing between the text and the month picker */
  }
  
  .revenue-month button {
    background: white;
    border: none;
    color: #0050A4 ;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .revenue-month span {
    margin: 0 10px;
    font-size: 15px;
    color: #0050A4 ;
  }
  
  .icon-container {
    background-color: white;
    padding: 5px;
    border-radius: 50%;
    
  }
  
  .icon-container img {
    width: 20px;
    height: 20px;
  }
  