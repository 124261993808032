.boxing{
    border: 1px solid rgba(0, 80, 164, 1);
    border-radius: 10px;
    margin-top: 20px;
}

.table-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    border: none;
    overflow: hidden;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    color:rgba(17, 84, 111, 1);
  }
  
  th {
    background-color: white;
    font-weight: bold;
    
  }
  
  tr:last-child td {
    border-bottom: none;
  }
/* Column widths */
/* th:first-child,
td:first-child {
  width: 8%;
} */

/* th:nth-child(2),
td:nth-child(2) {
  width: 65%;
} */

th:last-child,
td:last-child {
  width: 30% !important;
  text-align: left;
}

  
  .edit-button, .delete-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    font-size: 16px;
  }
  
  .edit-button {
    color: #007bff;
  }
  
  .delete-button {
    color: #dc3545;
  }
  img{
    /* margin-right: 1.2em; */
    cursor: pointer;
  }

  .Permissions{
    background-color: rgba(0, 80, 164, 1);
    margin-left: 1.2em;
    color: white;
    border-radius: 10px;
    padding: 10px, 22px, 10px, 28px;
    font-size: 16px
  }
  
  /* Font Awesome icons (make sure to include Font Awesome in your project) */
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');