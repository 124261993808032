.site-contact {
    background-color: rgba(226, 237, 249, 1);
    padding: 20px;
    border-radius: 5px;
  }

  /* .form-group-row{
    width: 50%;
  } */

  .previous-btn{
    background-color:rgba(0, 80, 164, 1) ;
    
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .form-group-foot{
    display: flex;
    justify-content: space-between;
  }




  .dropdown-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" stroke="%23000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-chevron-down" viewBox="0 0 24 24"><path d="M6 9l6 6 6-6"/></svg>') no-repeat right center;
    background-color: white;
    
  }
  
 

  