/* AddDesignationForm.css */

.add-designation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .add-designation-content {
    /* position: relative; */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .add-designation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .add-designation-header h3 {
    margin-bottom: 15px;
    font-size: 14px;
    color: rgba(0, 80, 164, 1);
    font-weight: bold;
  }
  
  .close-btn {
    float: right;
    margin: 0px !important;
    cursor: pointer;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .form-actions button {
    padding: 10px 20px;
    margin-left: 10px;
    /* border: none; */
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-actions button[type="submit"] {
    background-color: rgba(0, 80, 164, 1);
    color: white;
    border: 1px solid rgba(0, 80, 164, 1);
    font-size: 13px;
    font-weight: bold; 
  }
  
  .form-actions button[type="button"] {
    background-color: white;
    border: 1px solid rgba(0, 80, 164, 1);
    font-size: 13px;
    font-weight: bold;  
  }
  