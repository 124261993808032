/* UserTable.css */
.add-user-header{
    display: flex;
    justify-content: space-between;
}
.user-table-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .add-user-btn {
    background-color: white;
    color: black;
    border: 1px solid rgba(0, 80, 164, 1);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    /* margin-bottom: 20px; */
    
  }
 
  .user-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
    table-layout: fixed;
  }
  
  .user-table th,
  .user-table td {
    padding: 12px 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .user-table th {
    color: rgba(0, 80, 164, 1);
    font-weight: bold;
  }
  
   
  .user-table td {
    color: rgba(0, 80, 164, 1);
    font-weight: lighter;
  }
  
  .user-table th:nth-child(1),
  .user-table td:nth-child(1) {
    width: 5%;
  }
  
  .user-table th:nth-child(2),
  .user-table td:nth-child(2),
  .user-table th:nth-child(3),
  .user-table td:nth-child(3) {
    width: 12%;
  }
  
  .user-table th:nth-child(4),
  .user-table td:nth-child(4) {
    width: 17%;
  }
  
  .user-table th:nth-child(5),
  .user-table td:nth-child(5) {
    width: 15%;
  }
  
  .user-table th:nth-child(6),
  .user-table td:nth-child(6) {
    width: 22%;
  }
  
  .user-table th:nth-child(7),
  .user-table td:nth-child(7) {
    width: 13%;
  }
  
  .edit-btn,
  .delete-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin-right: 10px;
  }
  
  .add-user-form {
    max-width: 600px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border: 1px solid rgba(1, 22, 39, 1);
    border-radius: 10px;
    margin-top: 40px;
    
  }
  
  .add-user-form h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 14px;
    color: 
    rgba(0, 80, 164, 1);
  }
  
  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .form-group {
    flex: 0 0 48%;
  }
  
  .form-group.full-width {
    flex: 0 0 100%;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: black;
    font-size: 20px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 14px;
  }
  
  .form-actions {
    display: flex;
    justify-content:space-between;
    margin-top: 20px;
  }
  
  .cancel-btn,
  .save-btn {
    padding:10px, 22px, 10px, 22px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* margin-left: 10px; */
  }
  
  .cancel-btn {
    background-color: white;
    float: left;
    color:rgba(0, 80, 164, 1) ;
    width: 200px;
    font-size: 14px;
    border: 1px solid rgba(0, 80, 164, 1);
  }
  
  .save-btn {
    background-color:rgba(0, 80, 164, 1);
    color: white;
    width: 200px;
    font-size: 14px;
    float: right;
  }