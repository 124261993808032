.companies-overview {
  background-color: rgba(237, 244, 252, 1);
  padding: 20px;
  border-radius: 10px;
}

.overview-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.overview-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.overview-row {
  display: flex;
  align-items: stretch; /* Ensure all items in the row stretch to the same height */
  gap: 20px;
}

.overview-card,
.overview-chart {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  flex-grow: 1; /* Ensure the card and chart occupy the full height */
}

.card-icon {
  font-size: 32px;
  border: 1px solid rgba(236, 241, 252, 1);
  padding: 15px;
  background-color: 
  rgba(236, 241, 252, 1);
  border-radius: 18px;
  color: #4e79a7;
  margin-right: 10px;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-value {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.card-label {
  font-size: 14px;
  color: #888;
}

/* Updated Chart Content */
.chart-content {
  display: flex;
  justify-content: space-between; /* Space between left and right sections */
  align-items: center;
  width: 100%;
}

.chart-info {
  display: flex;
  flex-direction: column;
}

.chart-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.chart-value {
  /* display: flex; */
  align-items: baseline;
 
  font-weight: bold;
  color: #333;
}

.sub-value {
  font-size: 14px;
  color: #888;
  margin-left: 5px;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align the date and chart to the right */
  text-align: right; /* Right-align text */
}

.chart-date {
  font-size: 12px;
  color: #888;
  margin-bottom: 2em;
}


  
  /* 
  
  .companies-overview {
  background-color: #f4f8fd;
  padding: 20px;
  border-radius: 10px;
}

.overview-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.overview-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.overview-row {
  display: flex;
  align-items: stretch; 
  gap: 20px;
}

.overview-card,
.overview-chart {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-grow: 1; 
}

.card-icon {
  font-size: 32px;
  color: #4e79a7;
  margin-right: 10px;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-value {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.card-label {
  font-size: 14px;
  color: #888;
}

.chart-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  width: 100%;
}

.chart-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chart-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.chart-value {
  display: flex;
  align-items: baseline;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.sub-value {
  font-size: 14px;
  color: #888;
  margin-left: 5px;
}

.chart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-date {
  font-size: 12px;
  color: #888;
}


  */