.site-contact {
    background-color: rgba(226, 237, 249, 1);
    padding: 20px;
    border-radius: 5px;
  }

  .previous-btn{
    background-color:rgba(0, 80, 164, 1) ;
    
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .form-group-foot{
    display: flex;
    justify-content: space-between;
  }

  .contact-container {
    margin-bottom: 20px; 
  }
  
  .error-message {
    color: red;
    font-size: 12px; /* Optional: adjust font size as needed */
  }