/* YearlyOverviewChart.css */

.yearly-overview-chart {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 98%; 
    margin: 0 auto;
  }
  
  .chart-title {
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #333;
  }
  
  .chart-subtitle {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .chart-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0px;
  }
  
  .tab {
    margin-right: 15px;
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
    color: #444;
    border-bottom: 3px solid transparent;
  }
  
  .tab.active {
    color: #000;
    /* border-bottom: 3px solid #444; */
  }
  
  .apexcharts-legend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
  }
  
  .apexcharts-legend-series {
    display: flex;
    align-items: center;
  }
  
  .apexcharts-legend-text {
    margin-left: 5px;
    font-size: 12px;
    color: #444;
  }
  


  