style.css
.table-responsive {
  overflow-x: auto;
}


.inspections-container {

  margin: 0 auto;
 
}



.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
 
}

.controls .pageer {
  display: flex;
  align-items: center;
}

.controls input[type="text"] {
  padding: 8px, 9px, 8px, 9px;
  border: 1px solid rgba(158, 158, 158, 1);
  border-radius: 8px;
  width: 40rem; 
  margin-right: 10px;
}



.add-inspection-button {
  width: Hug (130px)px;
 font-size: 12px;

padding: 8px 12px 8px 12px;

border: none;
  padding: 10px 20px;
  background-color:rgba(247, 146, 30, 1);
  color: white;
 border-radius: 8px;

  cursor: pointer;
}
.add-inspection-button:hover {
  background-color: #0056b3;
}

.table {
  overflow-x: auto;
  border: 1px solid rgba(0, 80, 164, 1);
  border-radius: 10px;
}


.inspections-table {
  width: 100%;

}


.inspections-table th, .inspections-table td {
  font-size: 14px;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;



}

.inspections-table th {
  cursor: pointer;

}

.view-more-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.view-more-button .view {
  color: white;
  font-size: 12px;
  width: 91px;

padding: 8px 12px 8px 12px;

border-radius: 8px;


  background-color: rgba(0, 80, 164, 1);
  border: 1px solid rgba(0, 80, 164, 1);
}

.view-more-button:hover .view {
  text-decoration: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  border: none;
}

.pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .controls input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }

  .controls .pageer {
    width: 100%;
    justify-content: space-between;
  }

  .controls .pageer select {
    width: 100%;
  }



  .view-more-button {
    flex-direction: column;
    align-items: center;
  }

  .view-more-button .view {
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .pagination button {
    padding: 5px;
    margin: 2px;
  }

  .controls input[type="text"] {
    margin-bottom: 20px;
  }

  .controls .pageer {
    flex-direction: column;
    align-items: flex-start;
  }
}

.pageer span{
  font-size: 12px;
  margin: 4px;
}


.pageer select{
  background-color: rgba(224, 224, 224, 1);
  width: Hug (43px)px;
  font-size: 12px;
  border: none;
padding: 8px 9px 8px 9px;
gap: 4px;
border-radius: 8px ;

}

.status-new-request {
  background-color: rgba(239, 196, 113, 1);
  border: 1px solid rgba(239, 196, 113, 1);
  color: white;
 font-size: 12px;
 
  text-align: center;
padding: 8px 12px 8px 12px;
border-radius: 22px ;
gap: 10px;
}

.status-in-progress {
  background-color: rgba(123, 180, 239, 1);

  color: white;
  font-size: 12px;
  text-align: center;

  padding: 8px 12px 8px 12px;
  border-radius: 22px  ;  


}

.status-to-be-approved {
  background-color: rgba(69, 157, 67, 1);
 
  color: white;
  padding: 8px 12px 8px 12px;
  text-align: center;

  font-size: 12px;
  border-radius: 22px;
}

.status-approved {
  text-align: center;

  background-color:rgba(235, 249, 241, 1);
  border: 1px solid rgba(235, 249, 241, 1);
  color:  rgba(31, 146, 84, 1);
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 22px;
}




.additional-icons {
  display: flex;
  justify-content:space-between;
  /* margin-top: 10px; */
}

.additional-icons img {
  width: 25px;
  height: 25px;
}















