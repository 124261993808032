.bar-chart-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .bar-chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .bar-chart-header h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .year-selector {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
  }
  
  .bar-chart-body {
    display: flex;
    flex-direction: column;
  }
  
  .chart-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    /* color: rgba(71, 97, 149, 0.5); */
  }
  
  .tab {
    font-size: 14px;
    color: rgba(71, 97, 149, 0.5);
    cursor: pointer;
  }
  
  .tab.active {
    font-weight: bold;
    color: rgba(0, 42, 126, 1);
  }
  