.requests-status {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.status-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.status-select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 80, 164, 1);
  background-color: #fff;
  color: rgba(128, 124, 124, 1);
  font-size: 14px;
}

.status-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-chart {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.status-legend {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}



.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5em;
}


.legend-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.color-1 {
  background-color: #5D8DEC;
}

.color-2 {
  background-color: #3366CC;
}
/* .legend-content {
  display: flex;
  align-items: center;
  
  border-radius: 4px; 
}
.legend-item:hover .legend-content {
  border: 2px solid black; 
} */
.color-3 {
  background-color:  #476195; 
}

.color-4 {
  background-color: #8DADEB;
}

/* .legend-label {
  flex-grow: 1;
  font-size: 14px;
  color: #333;
  width: 100%;
} */

.legend-value {
 
  font-size: 12px;
  font-weight: bold;
  color: #333;
}


.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; 

}

.legend-content {
  display: flex;
  align-items: center;
  width: 98%;
  padding: 4px; /* Optional: Space between the content and the border */
  border-radius: 4px; /* Optional: Rounded corners */
  font-size: 12px; /* Set font size to 12px */
}

.legend-color {
  /* width: 100%;  */
  /* height: 20px;  */
  margin-right: 4px; 
}

.legend-label {
  width: 100%; 
  text-decoration: none;
  color: black;
  font-weight: 400;
 
  text-align: left; /* Align text to the left */
}

.legend-value {
  /* width: 10px;  */
  font-weight: 500;
  text-align: right; /* Align text to the right */
}

/* Optional: Hover effect */
.legend-item:hover  {
  border: 2px solid #3366CC; /* Customize the border color and thickness */
}

/* .legend-content */