.container {
    max-width: 100%;
    margin: 0 auto;
    margin-top: -4%;
    padding: 2em;
    /* margin-left: -2%; */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .add-site-button {
    background-color: #ff7f0e;
    color: white;
    border: none;
    padding-left:20px ;
    padding-right:20px ;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 2em;
  }
  
  .show-entries {
    display: flex;
    align-items: center;
  }
  
  .show-entries select {
    margin: 0 10px;
    padding: 5px;
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  /* .search-box input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
   */

.add-site-button btn{
  background-color: #F7921E;
  border: 1px solid #F7921E;
}

  .company-table {
    width: 100%;
    margin-top: 2em;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  .company-table-boxing{
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(0, 80, 164, 1);
  }
  
  .company-table th, .company-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .company-table th {
    cursor: pointer;
  }
  
  .status.new-request
   {
     background-color: rgba(239, 196, 113, 1);
     color: white;
     border-radius: 22px;
     padding: 10px 12px;
     font-size: 12px;
     border: 1px solid ; 
}
  .status.in-progress 
  { background-color: rgba(123, 180, 239, 1);
    color: white;
    border-radius: 22px;
    padding: 10px 12px !important;
    font-size: 12px; 
}
  .status.to-be-approved {background-color: rgba(69, 157, 67, 1);
    color: white;
    border-radius: 22px;
    padding: 10px 12px !important;
    font-size: 12px;  }
  .status.approved { background-color: rgba(235, 249, 241, 1);
    color:rgba(31, 146, 84, 1);
    border-radius: 22px;
    padding: 8px 10px !important;
    font-size: 12px;  }

    .status.active { background-color: rgba(235, 249, 241, 1);
      color:rgba(31, 146, 84, 1);
      border-radius: 22px;
      margin-left: 10px;
      padding: 8px 12px !important;
      font-size: 12px;  } 
  
  .view-request-button {
    padding: 5px 8px;
    background-color: rgba(0, 80, 164, 1);
    color: white;
    font-size: 12px;
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px;
  }

  img{
    cursor: pointer;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .pagination ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pagination li {
    margin: 0 5px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .pagination li.active {
    background-color: #007bff;
    color: white;
  }
  
  .pagination li.disabled {
    cursor: not-allowed;
    color: #ccc;
  }
  