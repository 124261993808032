/* AddDesignation.css */
.add-designation-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .add-designation-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  
  .add-designation-container h2 {
    margin-bottom: 15px;
    font-size: 14px;
    color: rgba(0, 80, 164, 1);
    font-weight: bold;
  }
  
  .form-group {
    /* margin-bottom: 15px; */
    color: rgba(0, 80, 164, 1) !important;
    font-size: 13px;
    font-weight: lighter;
    margin-bottom: 2px;
  }
  
 .Add-designation-close-btn{
    float: right;
    margin: 0px !important;
 }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    margin-top: 1px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .clear-btn, .add-btn, .close-btn {
    background-color: #007bff;
   
    padding: 10px 24px;
    /* border: none; */
    border-radius: 10px;
    cursor: pointer;
  }
  
  .clear-btn {
    background-color: white;
    border: 1px solid rgba(0, 80, 164, 1);
    font-size: 13px;
    font-weight: bold;  
}
  .add-btn{
    background-color: rgba(0, 80, 164, 1);
    color: white;
    border: 1px solid rgba(0, 80, 164, 1);
    font-size: 13px;
    font-weight: bold;  
  }
 
  
  
  
  .close-btn {
    margin-top: 20px;
    width: 100%;
  }
  
  .close-btn:hover {
    background-color: #0056b3;
  }
  .Add-Designation-form{
    display: flex;
    justify-content: space-between;
  }
  .Add-Designation-form button{
    float: right;
  }