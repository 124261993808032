.company-details {
    padding: 20px;
    background-color: white;
  }
  
  .company-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .history-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: rgba(0, 80, 164, 1);
    color: white;
    border: none;
    border-radius: 4px;
  }
  .export-button{
    margin-left: 10px;
    padding: 5px 10px;
    background-color: white ;
    color: rgba(0, 80, 164, 1);
    border: 1px solid rgba(0, 80, 164, 1);
    border-radius: 4px;

  }
  

  
  .add-company-button {
    padding: 10px 20px;
    background-color: rgba(0, 80, 164, 1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

 
  .search-and-add {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 20px;
  }


  .companyDetail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    margin-bottom: 4px;
    /* margin-top: 20px; */

  }

  .companyicons{
    padding-right: 10px;
    cursor: pointer;
  }
  
  .search-input {
    width: 60%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* .company-info {
    background-color: rgba(128, 167, 209, 0.1);
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  } */
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    padding-left: 10px;
    padding-top: 5px;
    color:  rgba(0, 80, 164, 1);
    background-color: 
    rgba(128, 167, 209, 0.1);
  }
  label{
    font-weight: bold;
   
  }
  .info-icon {
    color: #007bff;
    font-size: 18px;
    margin-left: 10px;
  }
  
  .status-badge {
    padding: 5px 10px;
    /* border-radius: 20px; */
    font-size: 12px;
    font-weight: bold;
  }
  
  .status-badge.active {
    color: #28a745;
    /* color: white; */
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
  }

  h2{
    color: rgba(0, 80, 164, 1);
  }

  .companyDetail{
    background-color:rgba(0, 80, 164, 1) !important ;
    border: 1px solid black;
  }

  h3{
    background-color:rgba(0, 80, 164, 1);
    color:white;
    padding: 2px;
  }


/* Add Company style |*/
.form{
    background-color: rgba(226, 237, 249, 1);
    padding: 10px;
    padding-bottom: 55px;
    color: rgba(0, 80, 164, 1);
    margin-top: 0px !important;

}


  .add-company-form {
    /* background-color: #f0f8ff; */
    padding: 20px;
    border-radius: 5px;
  }
  
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-tabs {
    display: flex;
    margin-bottom: 20px;

  }
  
  .form-tabs button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .form-tabs button.active {
    border-bottom: 2px solid blue;
    color:rgba(0, 80, 164, 1);  
}
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-row {
    display: flex;
    gap: 15px;
  }
  
  .form-row .form-group {
    flex: 1;
  }
  
  .next-button {
    background-color: blue;
    color: white;
    padding: 10px 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }

  h4{
    background-color: rgba(0, 80, 164, 1);
    color: white;
   margin-bottom: 0px;
  
    padding: 5px;
  }