/* styles.css */

/* Base styles */
.dashboard-file{
    background-color: rgba(237, 244, 252, 1);
    padding: 40px;
    padding-bottom: 5em;
}
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.5;
    
    /* color: #333; */
  }
  
  /* Custom utility classes */
  .shadow-custom {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .transition-custom {
    transition: all 0.3s ease;
  }
  
  /* Chart customizations */
  .apexcharts-tooltip {
    background: #fff;
    border: 1px solid #e2e8f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .apexcharts-xaxistooltip {
    background: #fff;
    border: 1px solid #e2e8f0;
  }
  
  /* Custom scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .grid-cols-5 {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .grid-cols-2 {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 640px) {
    .grid-cols-5 {
      grid-template-columns: 1fr;
    }
  }
  
  /* Custom animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Additional custom styles */
  .card-hover:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .text-gradient {
    background: linear-gradient(45deg, #4e79a7, #f28e2b);
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color: transparent;
  }