/* MainContactForm.css */
.main-contact-form {
    /* background-color: #f0f8ff; */
    padding: 20px;
    border-radius: 5px;
  }
  
  .form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .form-tabs button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .form-tabs button.active {
    border-bottom: 2px solid blue;
  }
  
  .company-contact {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  
  .contact-header {
    display: flex;
    background-color: 
    rgba(0, 80, 164, 1);
    padding: 2px;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 0px;
  }
  
  .form-row {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .form-group {
    flex: 1;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .previous-button,
  .save-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .previous-button {
    background-color: rgba(0, 80, 164, 1);
    color: white;
  }
  
  .save-button {
    background-color: rgba(0, 80, 164, 1);
    color: white;
  }
  
  .add-button,
  .delete-button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    margin-right: 1.5em;
    cursor: pointer;
  }
  
  .add-button {
    background-color:white;
    
    color:rgba(28, 28, 28, 1);
  }
  
  .delete-button {
    background-color: #f0f0f0;
    margin-right: 1em !important;  
}