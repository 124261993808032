.revenue-generated-container {
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-bottom: 0px;
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
}

.revenue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.revenue-header span {
  font-size: 21px;
  font-weight: bold;
}

.timeframe-buttons {
  display: flex;
}

.timeframe-buttons button {
  background-color: #0050A4;
  color: white;
  border: 1px solid #0050A4;
  font-size: 14px;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  /* margin-left: 5px; */
  border-radius: 4px;
}

.timeframe-buttons button.active {
  background-color: white;
  color: #0050A4;
  border: 1px solid white;
  border-radius: 10px;
}



.revenue-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  /* float: right; */
 
  margin-right: 0; /* Ensures no extra space */
}

.revenue-summary span {
  font-size: 14px;
  color: #14172A;
}

.revenue-amount {
  font-size: 14px;
  /* font-weight: bold; */
  margin-left: 10px;
}

.revenue-month {
  display: flex;
  align-items: center;
}

.revenue-month button {
  background-color: white;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #0050A4;
  margin: 0 10px;
}

.chart-container {
  
  clear: both; /* Ensures the chart container does not overlap with floated elements */
}
