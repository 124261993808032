/* PermissionsModal.css */
.permissions-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.permissions-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
}

.permissions-modal h2 {
  margin-bottom: 20px;
}

.permissions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.permission-item {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
}

.permission-item input[type="checkbox"] {
  margin-right: 10px;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.modal-actions button {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions .save-button {
  background-color: #203AAB;
  color: white;
}

/* Checkbox styling */
.permission-item input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #203AAB;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

.permission-item input[type="checkbox"]:checked {
  background-color: #203AAB;
  position: relative;
}

.permission-item input[type="checkbox"]:checked::before {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  right: 3px;
  top: 0;
}