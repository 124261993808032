.circular-chart-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .circular-chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .circular-chart-header h3 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .date-selector {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    border: 1px solid rgba(0, 80, 164, 1);
  }
  
  .date-selector span {
    margin: 0 5px;
    cursor: pointer;
  }
  
  .circular-chart-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .request-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
  }
  
  .request-item {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 12px;
    color: #666;
    padding: 2px;

  }
  
  .request-item:hover{
    border: 1px solid #0050A4;
    border-radius: 5px;
    cursor: pointer;
  }

  .request-item.selected {
    font-weight: bold;
    color: #333;
  }
  
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .dot.blue {
    background-color: #5D8DEC;
  }
  
  .dot.grey {
    background-color: #3366CC;
  }
  
  .dot.light-blue {
    background-color: #476195;
  }
  
  .dot.white {
    background-color: #8DADEB;

  }
  
  .request-count {
    margin-left: auto;
    font-weight: 400;
    font-size: 12;
    color: #333;
  }
  
 