/* styles.css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
  }
  
  .container {
    /* max-width: 40rem; */
    margin: 20px auto;
    background-color: #fff;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
  .tabs {
    display: flex;
  
    
  }
  
  .tabs button {
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid rgba(0, 80, 164, 1);
    border-radius: 10px;
   margin-right: 1.2em;
    color: #333;
  }
  
  .tabs button.active {
    border: 1px solid rgba(0, 80, 164, 1);
    background-color:rgba(0, 80, 164, 1) ;
    border-bottom: 2px solid #007bff;
    color: white;
    Padding: 10px, 22px, 10px, 22px;
    border-radius: 10px;
  }
  
  .profile-container {
    padding: 20px;
    border: 1px solid rgba(0, 80, 164, 1);
    border-radius: 10px;
    margin-top: 2em;
    padding-bottom: 5em;
    
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 20px;
  }
  
  .profile-info h2 {
    margin: 0;
    color:rgba(0, 80, 164, 1);
    font-size: 24px;
  }
  
  .profile-info p {
    /* margin: 5px 0; */
    font-size: 16px;
    color: #666;
  }
  .namep{
    margin-top: 5px;
    font-size: 16px;
    color: rgba(0, 80, 164, 1) !important;
}
  
  
  .change-password {
    margin-left: auto;
    padding: 8px 16px;
    background-color: white;
    color:
    rgba(0, 80, 164, 1);
    border: 1px solid rgba(0, 80, 164, 1);
    border-radius: 10px;
    font-weight:700;
    cursor: pointer;
  }
  
  .profile-form {
    display: grid;
    margin-top: 2em;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    color: rgba(0, 80, 164, 1);
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 1em;
  }
  
  .form-group input {
    padding: 8px;
    border: 1px solid rgba(63, 136, 165, 1);
    border-radius: 5px;
    color: rgba(63, 136, 165, 1);
    font-size: 16px;
  }
  
  /* .form-group input[readonly] {
    background-color: #f0f0f0;
  } */
  
  /* Make the Company Headquarters Address field full width */
  .form-group:last-child {
    grid-column: 1 / -1;
  }
  
  /* Styles for other pages */
  .designation-page,
  .permission-page,
  .user-page {
    padding: 20px;
  }
  
  .designation-page h2,
  .permission-page h2,
  .user-page h2 {
    color: #007bff;
  }

  .input-container {
    position: relative;
  }
  
  input {
    width: 100%;
    padding-right: 2rem; /* Space for the icon */
  }
  
  .icon {
    position: absolute;
    right: 1.0rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #0050A4;
  }
  
  .icon:hover {
    color: #000;
  }
  