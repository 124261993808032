h3,h5,Form.Label{
    color: rgba(0, 80, 164, 1);
}
/* 
.Container{
    background-color: rgba(226, 237, 249, 0.5);
} */

.Formlabel{
    color: rgba(0, 80, 164, 1);
}
.headtitle{
    border: 1px solid black;
    border-radius: 0.5em;
    background-color: rgba(153, 185, 219, 1);
    margin-top: 0;
    margin-bottom: 1em;
}
