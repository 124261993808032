.add-site {
    max-width: 40rem;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 80, 164, 1);
    /* font-size: 32px; */
}
  
  .export-btn {
    background-color: white;
    border: 1px solid rgba(0, 80, 164, 1);
    padding: 5px 10px;
    color: rgba(0, 80, 164, 1);
    cursor: pointer;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 20px;
  }
  
  .tab {
   
    background-color: white;
    border: none;
    color: rgba(1, 22, 39, 1);
    cursor: pointer;
  }
  
  .tab.active {
    background-color: white;
    color:rgba(0, 80, 164, 1);
  }
  .site-detail-h{
    background-color: rgba(0, 80, 164, 1);
    padding: 10px;
    color: white;
    font-size: 20px; 
    display: flex;
    justify-content: space-between;
  }
  .site-detail {
    background-color: rgba(226, 237, 249, 1);
    padding: 20px;
    border-radius: 5px;
  }
 
  .form-row {
    display: flex;
    gap: 20px;
    
    margin-bottom: 15px;
    color: rgba(0, 80, 164, 1);
    font-weight: bold;  
}
  
  .form-group {
    flex: 1;
  }
  
  /* label {
    display: block;
    margin-bottom: 5px;
  } */
  
  input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .next-btn {
    background-color:rgba(0, 80, 164, 1) ;
    
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    /* float: right; */
  }
  .form-group-foot{
    display: flex;
    justify-content: space-between;
  }