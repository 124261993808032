@import "variables";

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.scss";
@import "./layout/sidebar";
@import "./layout/container";

.light-gradient {
    background: linear-gradient(180.06deg, #84B1E1 -293.99%, #91B6DD -293.96%, #EDF4FC 68.83%);
}

.sidebar-arrow {
    display: inline-block;
    transition: transform 0.3s;
    line-height: normal;
    svg {
        height: 14px;
        transition: all 0.3s;
    }
  }
  
  .sidebar-arrow.down svg {
    transform: rotate(90deg);
  }
  
  .sidebar-arrow.right svg {
    transform: rotate(0deg);
  }
  
  .blueColor{
    color: rgba(0, 80, 164, 1);
  }
  .fs-32{
    font-size: 32px;
  }
  .fs-20{
    font-size: 20px;
  }
  .bg-blue{
    background-color: rgba(0, 80, 164, 1) !important;
  }

  .border-blue{
    border: 1px solid rgba(0, 80, 164, 1) !important;
  }

  .btn-1{
    width: 146px;
height: 56px;
font-size: 16px;
border-radius: 5px;
  }
.btn-2{
  Width:359px;
Height:60px;
}
  .border-radius-5{
    border-radius: 5px;
  }
  .imgicon{
    Width:28px;
Height:31.42px;
  }

  .bdc {
    margin: 0;

    ol {
      margin: 0;
      display: flex;
      align-items: center;
      padding: 10px;

      i {
        font-size: 20px;
      }

      span {
        font-size: 20px;
      }
    }
  }


  .form-tab {
    border: 1px solid #CBDBEC;
    border-radius: 10px;
    padding: 23px;

    h1 {
      color: rgba(0, 80, 164, 1);
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .nav {
      gap: 23px;
      .nav-item {
        a {
          background: rgba(203, 219, 236, 1);
          color: rgba(0, 80, 164, 1);
          cursor: pointer;
          box-shadow: -48px 2px 34.9px 0px rgba(0, 80, 164, 0.05);
          text-align: center;
          height: 63px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

          &.active {
            background: rgb(255, 255, 255);
          }
        }
      }
    }

    .next-prev-btn {
      background: rgba(0, 80, 164, 1);
      &:hover {
        background: rgb(0, 75, 155);
      }
    }

    
  }

  button.edit-form-btn {
    background: transparent !important;
    border: none !important;
    border-radius: 100%;
    overflow: hidden;
    height: 44px;
    padding: 0;
    img {
      max-width: 100%;
    max-height: 100%;
    }
  }
  button.add-form-button {
    background: rgb(255, 255, 255);
          color: rgba(0, 80, 164, 1);
          border: 1px solid rgba(0, 80, 164, 1);
          cursor: pointer;
          box-shadow: -48px 2px 34.9px 0px rgba(0, 80, 164, 0.05);
          text-align: center;
    font-size: 16px;
    height: 44px;
    &:hover {
      background: rgba(0, 80, 164, 1);
      color: rgb(255, 255, 255);
    }
    &.active {
      background: rgba(0, 80, 164, 1);
      color: rgb(255, 255, 255);
    }
  }
  